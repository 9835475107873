<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="tab-text">
                    <div class="vx-row">
                        <div class="vx-col md:w-1/3 w-full mt-2">
                            <vs-input
                                v-validate="'required|min:3'"
                                :data-vv-as="$t('root.functionName')"
                                data-vv-validate-on="blur"
                                type="account"
                                name="functionName"
                                :label="$t('root.functionName')"
                                v-model="permission.functionName"
                                autocomplete="off"
                                class="w-full"/>
                            <span class="text-danger text-sm">{{
                                    errors.first("functionName")
                                }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full mt-2">
                            <vs-input
                                v-validate="'required|min:3'"
                                :data-vv-name="'functionAlias'"
                                data-vv-validate-on="blur"
                                :data-vv-as="$t('root.displayName')"
                                type="account"
                                name="functionAlias"
                                :label="$t('root.displayName')"
                                v-model="permission.functionAlias"
                                autocomplete="off"
                                class="w-full"/>
                            <span class="text-danger text-sm">{{
                                    errors.first("functionAlias")
                                }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-2/3 w-full mt-2">
                            <vs-input
                                v-validate="'required|min:3'"
                                :data-vv-name="'functionApiPrefix'"
                                data-vv-validate-on="blur"
                                :data-vv-as="'Api Path'"
                                type="account"
                                name="functionApiPrefix"
                                label="Api Path"
                                v-model="permission.functionApiPrefix"
                                autocomplete="off"
                                class="w-full"/>
                            <span class="text-danger text-sm">{{
                                    errors.first("functionApiPrefix")
                                }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-2/3 w-full mt-2">
                            <vs-input
                                v-validate="'required|min:3'"
                                :data-vv-name="'functionScreenPrefix'"
                                data-vv-validate-on="blur"
                                :data-vv-as="'Screen Path'"
                                type="account"
                                name="functionScreenPrefix"
                                label="Screen Path"
                                v-model="permission.functionScreenPrefix"
                                autocomplete="off"
                                class="w-full"/>
                            <span class="text-danger text-sm">{{
                                    errors.first("functionScreenPrefix")
                                }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="mt-4 mb-4 flex flex-wrap items-center justify-end">
                                <vs-button class="ml-auto mt-2" @click="saveChanges"
                                           :disabled="!validateForm">{{
                                        `${isUpdate ?
                                            this.$t('button.update') :  this.$t('button.create') }`
                                    }}
                                </vs-button>
                                <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.go(-1)">
                                   {{$t('button.back')}}
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isUpdate: false,
            id: null,
            permission: {},
        };
    },
    components: {
    },
    computed: {
        validateForm() {
            return !this.errors.any();
        },
    },
    created() {
        this.permissionId = this.$route.query.id;
        if (this.permissionId) {
            this.$vs.loading();
            this.$crm.post('/permission-function/find-by-id/' + this.permissionId).then((response) => {
                this.$vs.loading.close();
                this.permission = response.data;
                this.isUpdate = true;
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    methods: {
        async saveChanges(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            let path = this.isUpdate ? ('/permission-function/update/' + this.permissionId) : '/permission-function/create';
            let params = this.isUpdate ? this.permission : [this.permission];
            this.$vs.loading();
            this.$crm.post(path, params).then(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                    color: 'success',
                    text: `${this.isUpdate ?  this.$t('button.update') :  this.$t('button.add')} ${this.$t('root.functionalSuccess')}`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$router.push('/admin/permission-function');
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    }
};
</script>
